@font-face {
  font-family: header;
  src: url(../Audiowide-Regular.ttf);
}
.wallet-adapter-button {
  line-height: 20px !important;
  margin-top: 1.4rem;
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: lighter !important;
  border-radius: 20px !important;
}
.not__connected__text {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 3vw;
  color: white;
}
.wallet__btn {
  text-align: left;

}
.wallet__select {
  font-size: 1.1vw !important;
}

.zoom-in-out-box:hover {
  animation: zoom-in-zoom-out 1s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}
.img__container{
  margin-left: 1vw;
  margin-top: 2vh;
}

@media screen and (max-width: 600px) {
  .wallet__select {
    font-size: 4.3vw !important;
  }
  .wallet__btn {
    text-align: center;

  }
  h2 {
    font-size: 3vw;
  }
  .img__container{
   
    margin-left: 1vw;

  }
  .zoom-in-out-box:hover {

    width: 100%;
    height: 100%;
  }
  .img__size{
    width: 100%;
    height: 60%;
  }
  
}
