.App {
  text-align: center;
  margin-left: 2rem;
}
.bkg{
  background-image: url(./card.png);
  background-size: cover;
  background-color: transparent !important;
  background-repeat: no-repeat;
  padding: 2rem;
  border-radius: 20px !important; 
  border: 1px solid rgb(68, 192, 230);
   height:100%
}
body{
  font-family: Arial, Helvetica, sans-serif !important; 
}
table {
   font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid rgb(68, 192, 230);
  text-align: left;
  padding: 4px;
  /* background-color: rgb(68, 192, 230); */
  font-size: 0.7vw;
  color: rgb(252, 235, 235);
}

tr:nth-child(even) {
  background-color: rgb(68, 192, 230);
}
.heading {
   font-family: Arial, Helvetica, sans-serif;
  color: white;
  font-size: 2vw;
}
.not__connected__text {
  font-size: 4vw;
}
body {
  background-color: #03010e;
  padding: 3rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.text__name{
  color: white;
  font-size: 1vw;
     font-family: Arial, Helvetica, sans-serif !important;
}
.text__descp{
  color: white !important;
  font-size: 0.9vw !important;
     font-family: Arial, Helvetica, sans-serif !important;

}

.each__row{
  margin-top:12vh !important;
  text-align: center;
}
.content{
 position: relative;
 left: 1vw;
}
@media screen and (max-width: 600px) {
  .each__row{
    margin-top: 8vh !important;
    text-align: center;
  }
  .App {
    text-align: center;
    margin-left: 0rem;
  }
  .bkg{
    background-image: url(./card.png);
    background-size: cover;
    background-color: transparent !important;
    background-repeat: no-repeat;
    height:90%;
    position: relative;
    left: 1.8vw;
  }
  .content{
    position: relative;
    left: 3vw;
   }
   .heading{
     font-size: 5vw;
     position: relative;
     left: 2vw;
   }
  .img__container{
    position: relative;
    left: 2vw;
  }
  .text__descp{
    color: white !important;
    font-size: 2vw !important;
       font-family: Arial, Helvetica, sans-serif !important;
  
  }
  td, th {

    font-size: 1.5vw;
 
  }

}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
